<template>
  <b-field label="Group by" horizontal class="label-long field-nested">
    <b-select :value="value" @input="v => $emit('input', v)">
      <option v-for="period in reportingPeriods" :key="period" :value="period"> {{ periodLabels[period] }} </option>
    </b-select>
  </b-field>
</template>

<script>
import ReportingPeriod from "@/enums/reportingPeriod";

export default {
  props: {
    value: { type: Number, required: true },
    initValue: { type: Number, required: true }
  },
  data() {
    return {
      periodLabels: ReportingPeriod.Label
    };
  },
  computed: {
    reportingPeriods() {
      const periods = [];
      for (const [, p] of Object.entries(ReportingPeriod.Enum)) {
        // add this and higher periods
        // but exclude week starting for day
        // and week ending for week starting
        if (
          p >= this.initValue &&
          !(this.initValue === ReportingPeriod.Enum.Day && p === ReportingPeriod.Enum.WeekStarting) &&
          !(this.initValue === ReportingPeriod.Enum.WeekStarting && p === ReportingPeriod.Enum.WeekEnding)
        ) {
          periods.push(p);
        }
      }

      return periods;
    }
  }
};
</script>
