<template>
  <b-field label="Category" horizontal>
    <b-select placeholder="Select KPI Category" :value="value" @input="selectCategory">
      <option v-for="option in categories" :value="option.auditSectionCategoryId" :key="option.auditSectionCategoryId">
        {{ option.header }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  props: {
    auditTypeId: { type: Number, default: 0 },
    value: { type: Number }
  },
  data() {
    return {
      isLoading: false,
      categories: []
    };
  },
  watch: {
    auditTypeId: {
      immediate: true,
      handler: "getCategories"
    }
  },
  methods: {
    async getCategories() {
      // must have an Audit type selected
      if (!this.auditTypeId) return;
      await this.$http
        .get("audittypes/categories/" + this.auditTypeId)
        .then(r => {
          this.categories = r.data.sectionCategories;
          // only one select it
          if (this.categories.length === 1) {
            this.selectCategory(this.categories[0].auditSectionCategoryId);
          } else {
            this.selectCategory(null);
          }
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading categories"));
    },
    selectCategory(v) {
      this.$emit("input", v);
    }
  }
};
</script>
