<template>
  <b-field label="KPI" horizontal>
    <b-select placeholder="Select KPI" :value="value" @input="selectQuestion">
      <option v-for="option in questions" :value="option.auditQuestionId" :key="option.auditQuestionId">
        {{ option.question }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  props: {
    auditSectionId: { type: Number, default: 0 },
    value: { type: Number, default: 0 }
  },
  data() {
    return {
      isLoading: false,
      questions: []
    };
  },
  watch: {
    auditSectionId: {
      immediate: true,
      handler: "getQuestions"
    }
  },
  methods: {
    async getQuestions() {
      this.questions = [];
      this.selectQuestion(null);
      // must have an Audit Section selected
      if (!this.auditSectionId) return;
      await this.$http
        .get("audittypes/questions/" + this.auditSectionId)
        .then(r => {
          this.questions = r.data;
          if (this.questions.length === 1) {
            this.selectQuestion(this.questions[0].auditQuestionId);
          } else {
            this.selectQuestion(null);
          }
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading KPIs"));
    },
    selectQuestion(v) {
      this.$emit("input", v);
    }
  }
};
</script>
