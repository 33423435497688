<template>
  <section class="section is-main-section" @mousedown="captureMouse">
    <div class="box">
      <div class="columns">
        <div class="column is-5">
          <AuditTypeSelect v-model="auditTypeId" :selectFirstValue="true" />
          <b-field label="KPI Area(s)" horizontal class="field-nested">
            <b-field>
              <multiselect
                class="control"
                v-model="selectedSections"
                :options="sections"
                group-label="header"
                group-values="sections"
                :group-select="true"
                track-by="auditSectionId"
                label="header"
                :multiple="true"
                :taggable="true"
                tag-placeholder=""
                :max-height="250"
                :limit="3"
                placeholder="Select KPI Areas to graph"
                deselectLabel="Remove from graph"
                selectLabel="Add to graph"
                selectedLabel="Shown on graph"
                selectGroupLabel="Select all in Category"
                deselectGroupLabel="Remove all in Category"
                @remove="removeSection"
                @select="debounceGetData"
              >
                <template v-slot:option="props">
                  {{ props.option.$isLabel ? props.option.$groupLabel : "\xA0\xA0\xA0└─ " + props.option.header }}
                </template>
              </multiselect>
              <p class="control">
                <b-button icon-left="close-circle-outline" title="Clear All" @click="clearAll" />
              </p>
            </b-field>
          </b-field>
          <b-field v-if="oneKpiArea" label="Show Individual KPIs" horizontal class="label-long">
            <b-checkbox v-model="showIndividualKpis" />
          </b-field>
        </div>
        <div class="column">
          <SelectLocation v-model="locationId" required />
        </div>
        <div class="column">
          <ReportingGroupBy v-model="reportingPeriod" :initValue="selectedAuditTypeReportingPeriod" />
          <reporting-period
            :reportingPeriod="reportingPeriod"
            :reportingWeekDay="reportingWeekDay"
            :fromDate="fromDate"
            :toDate="toDate"
            @setRange="setRange"
          />
        </div>
      </div>
    </div>

    <div class="twoColumns">
      <card-component :title="prev6PeriodTitle" icon="finance" header-icon="reload" @header-icon-click="debounceGetData">
        <div v-if="isLoading"><LoadingData /></div>
        <div v-if="periodChart.chartData" class="chart-area">
          <line-chart
            style="height: 100%;"
            chart-id="prev6Months"
            ref="periodChart"
            :chart-data="periodChart.chartData"
            :options="periodChart.extraOptions"
          />
        </div>
        <NoData v-if="!isLoading && !periodChart.chartData" />
      </card-component>
    </div>
    <div class="twoColumns">
      <card-component :title="breakDownTitle" icon="finance" header-icon="reload" @header-icon-click="debounceGetData">
        <div v-if="isLoading"><LoadingData /></div>
        <div class="chart-area">
          <line-chart
            v-if="breakDownChart.chartData"
            chart-id="breakDown"
            ref="breakDown"
            :chart-data="breakDownChart.chartData"
            :options="breakDownChart.extraOptions"
          />
        </div>
        <template class="chart-area" v-for="section in kpiDetails">
          <div class="columns" :key="section.sectionId">
            <div class="column sectionHeader">
              {{ section.header }}
            </div>
          </div>
          <div class="columns row-space-narrow" v-for="question in section.data" :key="'q' + question.questionId">
            <div class="column is-8 has-text-right has-text-weight-bold field-nested">
              <p class="field-label">{{ question.question }}</p>
            </div>
            <div class="column is-2">
              <AuditInputType v-model="question.score" :inputType="question.inputType" :disabled="true" />
            </div>
            <div class="column field-nested">
              <a v-if="question.notes">
                <b-tooltip :label="question.notes" multilined type="is-primary is-light">
                  <b-icon class="field-label" icon="message" />
                </b-tooltip>
              </a>
            </div>
          </div>
        </template>
        <NoData v-if="!isLoading && !breakDownChart.chartData && !kpiDetails.length" />
      </card-component>
    </div>

    <div class="is-clearfix"></div>
    <ContextMenu ref="ctx" :delay="0" :menuOptions="contextOptions" @menu-clicked="menuClicked" />
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import qs from "qs";
import "vue-multiselect/dist/vue-multiselect.min.css";
import distinctColors from "distinct-colors";

import * as chartConfig from "@/components/Charts/chart.config";
import CardComponent from "@/components/CardComponent";
import LineChart from "@/components/Charts/LineChart";
import SelectLocation from "@/components/SelectLocation.vue";
import AuditTypeSelect from "@/components/kpi/AuditTypeSelect.vue";
import ReportingPeriod from "@/components/kpi/ReportingPeriod.vue";
import ReportingGroupBy from "@/components/kpi/ReportingGroupBy.vue";
import ContextMenu from "@/components/ContextMenu";
import ContextMenuMixin from "@/mixins/contextMenuMixin";
import AuditInputType from "@/components/kpi/AuditInput.vue";
import AddKpiDialog from "@/components/kpi/AddKpiDialog.vue";

import NoData from "@/components/NoData.vue";
import LoadingData from "@/components/LoadingData.vue";

import ReportingPeriodEnum from "@/enums/reportingPeriod";
import Dates from "@/common/dates";

import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  components: {
    Multiselect,
    LineChart,
    CardComponent,
    SelectLocation,
    ReportingPeriod,
    AuditTypeSelect,
    AuditInputType,
    NoData,
    LoadingData,
    ReportingGroupBy,
    ContextMenu
  },
  mixins: [ContextMenuMixin],
  data() {
    const extraOptions = {
      ...chartConfig.chartOptionsMain
    };

    return {
      isLoading: false,
      activeTab: 0,
      activeTabs: { Audits: 0, Actions: 1 },
      locationId: null,
      locations: null,
      auditTypeId: null,
      reportingWeekDay: 0,
      sections: [],
      selectedSections: [],
      additionalSections: [],
      defaultSections: [], // temp store for sections on query string, but not loaded yet
      additionalKpis: [],
      fromDate: null,
      toDate: null,
      colours: distinctColors({ count: 20 }),
      reportingPeriod: ReportingPeriodEnum.Enum.Day,

      periodChart: {
        chartData: null,
        extraOptions: {
          ...extraOptions,
          // scales: {
          //   xAxes: [{ stacked: true }]
          //   // yAxes: [{ stacked: true }]
          // },
          onClick: this.handlePeriodChartClick
        }
      },
      breakDownChart: {
        chartData: null,
        extraOptions: {
          ...extraOptions,
          onClick: this.handleChartLocationClick
        }
      },
      kpiDetails: [],
      breakDownLocationIds: [],
      showIndividualKpis: false,
      // create debounced GetData method that we call when parameters change
      // Changing the AuditType can result in date range changing, so without debouce it will search twice
      debounceGetData: _.debounce(this.getData, 100),

      datePeriodContext: null,
      sectionIdContext: null,
      locationContext: null
    };
  },
  computed: {
    ...mapGetters("auditTypes", ["selectedAuditTypeReportingPeriod", "selectedAuditTypeReportingWeekDay"]),
    ...mapGetters("locations", ["selectedLocationName", "selectedLocationNoChildren"]),
    prev6PeriodTitle() {
      return `${this.selectedLocationName} previous 6 ${ReportingPeriodEnum.Plural[this.reportingPeriod]}`;
    },
    breakDownTitle() {
      // format using the fromDate, except for the WeekEnding
      // had to explicitly refrence this.toDate and this.fromDate to ensure property got updated
      const to = this.toDate;
      const from = this.fromDate;
      const displayDate = this.reportingPeriod === ReportingPeriodEnum.Enum.WeekEnding ? to : from;
      // if no children for location then we display the KPI's for that location
      const title = this.selectedLocationNoChildren ? "KPIs" : "Breakdown";
      return `${this.selectedLocationName} ${title} for ${ReportingPeriodEnum.Label[this.reportingPeriod]} ${Dates.periodFormat(
        displayDate,
        this.reportingPeriod
      )}`;
    },
    oneKpiArea() {
      return this.selectedSections.length === 1;
    },
    contextOptions() {
      var options = [];
      if (this.datePeriodContext !== null && this.datePeriodContext !== this.periodChart.chartData?.labels?.length - 1) {
        options.push({ text: "Go to this Date", event: "goto-date" });
      }
      if (this.locationContext) {
        options.push({ text: "Go to this Location", event: "goto-location" });
      }
      // these options only available for primary sections/kpi
      if (!this.isAddtionalContext) {
        if (this.showIndividualKpis) {
          options.push({ text: "Show KPI Area", event: "show-area" });
        } else {
          if (this.sectionIdContext !== null) {
            options.push({ text: "Show KPIs", event: "show-kpis" });
          }
          if (!this.oneKpiArea) {
            options.push({ text: "Show Target", event: "show-target" });
          }
        }
      }
      options.push({ text: "Add Other KPI" + (this.showIndividualKpis ? "" : " Area"), event: "add-kpi" });
      return options;
    }
  },
  watch: {
    // reportingWeekDay is set from selectedAuditTypeReportingWeekDay, but also from query parse so that we can get correct
    // from/to dates on the reporting group from the querystring
    selectedAuditTypeReportingWeekDay: {
      immediate: true,
      handler(v) {
        this.reportingWeekDay = v;
      }
    },
    selectedAuditTypeReportingPeriod: {
      immediate: true,
      handler(v) {
        // default date to previous period if value set
        if (v >= 0) this.toDate = Dates.previousPeriod(null, v, 1);
        // default the reportingPeriod to same as selected audit
        this.reportingPeriod = v;
      }
    },
    locationId: "debounceGetData",
    toDate: "debounceGetData",
    reportingPeriod: "debounceGetData",
    showIndividualKpis: "debounceGetData",
    auditTypeId() {
      this.getSections();
    },
    "$route.query": {
      immediate: true,
      handler: "safeParseQueryString"
    }
  },
  methods: {
    setRange(r) {
      this.fromDate = r.from;
      this.toDate = r.to;
    },
    safeParseInt(current, value) {
      let intValue = parseInt(value);
      if (!intValue) intValue = current;
      return intValue;
    },
    safeParseQueryString(query) {
      if (this.queryStringDiffForCurrent(query)) {
        this.auditTypeId = this.safeParseInt(this.auditTypeId, query.auditTypeId);
        this.reportingWeekDay = this.safeParseInt(this.reportingWeekDay, query.reportingWeekDay);
        this.locationId = this.safeParseInt(this.locationId, query.locationId);
        this.reportingPeriod = this.safeParseInt(this.reportingPeriod, query.reportingPeriod);
        this.fromDate = Dates.safeParseDate(this.fromDate, query.fromDate);
        this.toDate = Dates.safeParseDate(this.toDate, query.toDate);
        this.showIndividualKpis = this.safeParseBool(this.showIndividualKpis, query.inidvidualAnswers);
        this.safeSetSelectedSection(query.sectionIds);
        this.safeSetQuestionsId(query.questionIds);
      }
    },
    safeParseBool(current, value) {
      return value === "true" || value === "false" ? JSON.parse(value) : current;
    },
    safeSetSelectedSection(newIds) {
      // first check there are all valid ids
      if (Array.isArray(newIds)) {
        const newArray = [];
        newIds.forEach(id => {
          const sectionId = this.safeParseInt(null, id);
          if (sectionId) newArray.push(sectionId);
        });
        newArray.forEach((id, i) => {
          if (i === 0) this.setSelectedSection(id);
          else this.addSelectedSection(id);
        });
      } else {
        const sectionId = this.safeParseInt(null, newIds);
        if (sectionId) this.setSelectedSection(sectionId);
      }
    },
    safeSetQuestionsId(newIds) {
      // first check there are all valid ids
      if (Array.isArray(newIds)) {
        const newArray = [];
        newIds.forEach(id => {
          const questionId = this.safeParseInt(null, id);
          if (questionId) newArray.push(questionId);
        });
        if (newArray.length) this.additionalKpis = newArray;
      } else {
        const questionId = this.safeParseInt(null, newIds);
        if (questionId) this.additionalKpis = [questionId];
        else this.additionalKpis = [];
      }
    },

    async getSections() {
      // must have an Audit type selected
      if (!this.auditTypeId) return;
      this.clearAll();
      await this.$http
        .get("audittypes/allsections/" + this.auditTypeId)
        .then(r => {
          this.sections = r.data.sectionCategories;
          // if we have a query defined it will have set the sections in defaultSections, otherwise get the default
          if (this.defaultSections.length) {
            this.setSelectedSections(this.defaultSections);
            this.defaultSections = [];
          }
          if (!this.selectedSections.length) this.selectedSections = this.findDefaultSections();

          // as we handle the add/remove of select sections via the @remove and @select events, we need to ensure inital search is called here
          this.debounceGetData();
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading sections"));
    },
    async getData() {
      // must have an Audit type selected and some sections
      if (!this.auditTypeId || !this.selectedSections.length || this.reportingPeriod < 0) return;
      if (!this.oneKpiArea) this.showIndividualKpis = false;
      if (this.isLoading) return; // this prevents showIndividualKpis kicking off another load

      this.isLoading = true;
      const qry = this.getReportParms();

      await this.$http
        .get("auditReports", {
          params: qry,
          paramsSerializer: params => {
            return qs.stringify(params);
          }
        })
        .then(r => {
          this.setBreakDownData(r.data.locations);
          this.setPeriodData(r.data.sections);
          this.kpiDetails = r.data.details || [];

          // Add to Router path, so we can go back, replace if default search, ie no qry params defined yet
          const currentQs = this.$route.query;
          if (currentQs.auditTypeId) {
            // only push new state if different
            if (this.queryStringDiffForCurrent(currentQs)) {
              this.$router.push({ query: this.toQueryString(qry) });
            }
          } else {
            this.$router.replace({ query: this.toQueryString(qry) });
          }
        })
        .catch(e => {
          this.$alerts.showErrorAlert(e, "Error loading KPIs");
        });
      this.isLoading = false;
    },
    getReportParms() {
      return {
        locationId: this.locationId,
        auditTypeId: this.auditTypeId,
        reportingWeekDay: this.reportingWeekDay,
        sectionIds: this.selectedSections.map(s => s.auditSectionId).concat(this.additionalSections.map(s => s.auditSectionId)),
        questionIds: this.additionalKpis,
        // handle dates in standard format for comparison and to remove TZ component
        fromDate: Dates.formatYYYYMMDD(this.fromDate),
        toDate: Dates.formatYYYYMMDD(this.toDate),
        reportingPeriod: this.reportingPeriod,
        inidvidualAnswers: this.showIndividualKpis
      };
    },
    toQueryString(params) {
      var copy = { ...params };
      // if only one element in array, qs changes it to non array
      copy.sectionIds = this.removeArrayIfOneElement(copy.sectionIds);
      copy.questionIds = this.removeArrayIfOneElement(copy.questionIds);
      return copy;
    },
    removeArrayIfOneElement(array) {
      if (!array.length) return null;
      if (array.length === 1) return array[0];
      return array;
    },
    queryStringDiffForCurrent(query) {
      return qs.stringify(query) !== qs.stringify(this.toQueryString(this.getReportParms()));
    },
    setBreakDownData(data) {
      if (!data?.length) return this.clearChart(this.breakDownChart);

      this.breakDownChart.chartData = {
        labels: [...new Set(data[0].data.map(d => d.locationName))],
        datasets: data.map((s, i) => {
          let label = this.showIndividualKpis ? s.question : this.selectedSections.find(a => a.auditSectionId === s.sectionId)?.header;
          const isAdditionalSection = label === undefined;
          if (isAdditionalSection) label = this.additionalSections.find(a => a.auditSectionId === s.sectionId)?.header;

          return {
            ...chartConfig.defaultDataSetOptions,
            data: s.data.map(a => a.score),
            target: s.data.map(a => a.target),
            label: label,
            borderColor: this.colours[i],
            pointBackgroundColor: this.colours[i],
            pointHoverBackgroundColor: this.colours[i],
            pointHoverRadius: 10,
            pointHitRadius: 10,
            sectionId: s.sectionId, // stored to use in handleClick
            isAdditionalSection: isAdditionalSection // used to determine if we can drill down
          };
        })
      };

      // If only one show the target if available
      this.addTargetLine(this.breakDownChart.chartData);

      this.breakDownLocationIds = data[0].data.map(d => d.locationId);
    },
    addTargetLine(chartData) {
      if (chartData.datasets.length === 1) {
        const targets = chartData.datasets[0].target; // get save targets from dataset

        // if all targets are null, we don't want it added
        var anyTargets = targets?.find(t => t !== null);
        if (anyTargets !== null) {
          chartData.datasets.push({
            ...chartConfig.defaultDataSetOptions,
            data: targets,
            label: this.selectedSections[0].header + " Target",
            borderColor: chartConfig.chartColors.default.danger,
            pointBackgroundColor: chartConfig.chartColors.default.danger,
            pointHoverBackgroundColor: chartConfig.chartColors.default.danger
          });
        }
      }
    },
    setPeriodData(data) {
      if (!data?.length) return this.clearChart(this.periodChart);

      this.periodChart.chartData = {
        labels: [...new Set(data[0].data.map(d => Dates.periodFormat(d.toDate, this.reportingPeriod)))],
        datasets: data.map((s, i) => {
          let label = this.showIndividualKpis ? s.question : this.selectedSections.find(a => a.auditSectionId === s.sectionId)?.header;
          const isAdditionalSection = label === undefined;
          if (isAdditionalSection) label = this.additionalSections.find(a => a.auditSectionId === s.sectionId)?.header;

          return {
            ...chartConfig.defaultDataSetOptions,
            data: s.data.map(a => a.score),
            target: s.data.map(a => a.target), // Target save off in dataset for later display
            label: label,
            borderColor: this.colours[i],
            pointBackgroundColor: this.colours[i],
            pointHoverBackgroundColor: this.colours[i],
            backgroundColor: this.colours[i],
            hoverBackgroundColor: this.colours[i],
            pointHoverRadius: 10,
            pointHitRadius: 10,
            sectionId: s.sectionId, // stored to use in handleClick
            isAdditionalSection: isAdditionalSection // used to determine if we can drill down
          };
        })
      };

      this.addTargetLine(this.periodChart.chartData);
    },

    clearChart(chart) {
      chart.chartData = null;
    },
    clearAll() {
      this.selectedSections = [];
      this.clearChart(this.breakDownChart);
      this.clearChart(this.periodChart);
    },
    hideSection(section, chart) {
      if (!chart.chartData) return;
      if (this.oneKpiArea) return this.clearChart(chart);

      var newDataset = chart.chartData.datasets.filter(d => d.sectionId !== section.auditSectionId);
      chart.chartData = {
        labels: chart.chartData.labels,
        datasets: newDataset
      };
      // Add target line if only one, form our saved targets
      this.addTargetLine(chart.chartData);
    },
    removeSection(section) {
      this.hideSection(section, this.breakDownChart);
      this.hideSection(section, this.periodChart);
    },
    handleChartLocationClick(evt, elements) {
      var chart = this.$refs.breakDown.$data._chart;
      const chartIndex = chart.getElementAtEvent(evt);

      this.datePeriodContext = null;
      this.locationContext = null;
      this.sectionIdContext = null;
      this.isAddtionalContext = null;

      if (chartIndex.length !== 0) {
        const datasetIndex = chartIndex[0]._datasetIndex;
        this.locationContext = this.breakDownLocationIds[chartIndex[0]._index];
        if (datasetIndex >= 0) {
          const ds = chart.tooltip._data.datasets[datasetIndex];
          this.sectionIdContext = ds.sectionId;
          this.isAddtionalContext = ds.isAdditionalSection;
        }
        this.showContextMenu();
      }
    },
    handlePeriodChartClick(evt) {
      var chart = this.$refs.periodChart.$data._chart;
      const chartIndex = chart.getElementAtEvent(evt);

      this.datePeriodContext = null;
      this.locationContext = null;
      this.sectionIdContext = null;
      this.isAddtionalContext = null;

      if (chartIndex.length !== 0) {
        const datasetIndex = chartIndex[0]._datasetIndex;
        this.datePeriodContext = chartIndex[0]._index;

        if (datasetIndex >= 0) {
          const ds = chart.tooltip._data.datasets[datasetIndex];
          this.sectionIdContext = ds.sectionId;
          this.isAddtionalContext = ds.isAdditionalSection;
        }
        this.showContextMenu();
      }
    },
    removeOtherSections(chart, keepSectionId) {
      var newDataset = chart.chartData?.datasets.filter(d => d.sectionId === keepSectionId);
      if (!newDataset) return;

      chart.chartData = {
        labels: chart.chartData.labels,
        datasets: newDataset
      };
      this.setSelectedSection(keepSectionId);
      this.addTargetLine(chart.chartData);
    },
    setSelectedSection(sectionId) {
      if (this.sections.length) {
        const section = this.findSection(sectionId);
        if (section) this.selectedSections = [section];
      } else {
        // if sections not loaded yet we save off in defaultSections array
        this.defaultSections = [sectionId];
      }
    },
    setSelectedSections(sectionIds) {
      sectionIds.forEach((id, i) => {
        if (i === 0) this.setSelectedSection(id);
        else this.addSelectedSection(id);
      });
    },
    addSelectedSection(sectionId) {
      if (this.sections.length) {
        const section = this.findSection(sectionId);
        if (section) this.selectedSections.push(section);
      } else {
        // if sections not loaded yet we save off in defaultSections array
        this.defaultSections.push(sectionId);
      }
    },
    findSection(sectionId) {
      let section = null;
      this.sections.some(category => {
        section = category.sections.find(s => s.auditSectionId === sectionId);
        return section;
      });
      return section;
    },
    findDefaultSections() {
      let sections = [];
      this.sections.forEach(category => {
        sections = sections.concat(category.sections.filter(s => s.default));
      });
      return sections;
    },
    menuClicked(event) {
      switch (event) {
        case "goto-date":
          if (this.datePeriodContext !== null) {
            this.toDate = Dates.previousPeriod(
              this.toDate,
              this.reportingPeriod,
              this.periodChart.chartData.labels.length - this.datePeriodContext - 1
            );
          }
          break;
        case "show-kpis":
          if (this.sectionIdContext) {
            this.clearAdditions();
            this.setSelectedSection(this.sectionIdContext);
          }
          this.showIndividualKpis = true;
          break;
        case "show-area":
          this.showIndividualKpis = false;
          this.clearAdditions();
          break;
        case "show-target":
          if (this.sectionIdContext) {
            this.clearAdditions();
            this.removeOtherSections(this.breakDownChart, this.sectionIdContext);
            this.removeOtherSections(this.periodChart, this.sectionIdContext);
          }
          break;
        case "goto-location":
          if (this.locationContext) {
            this.locationId = this.locationContext;
            this.$store.dispatch("locations/selectLocationId", this.locationContext);
          }
          break;
        case "add-kpi":
          this.$buefy.modal.open({
            parent: this,
            component: AddKpiDialog,
            props: { showIndividualKpis: this.showIndividualKpis },
            events: { addKpi: this.addNewKpi },
            hasModalCard: true,
            trapFocus: true
          });
          break;
      }
    },
    addNewKpi(selection) {
      if (this.showIndividualKpis) {
        this.additionalKpis.push(selection.auditQuestionId);
      } else {
        // if user selected same group, we just add that as main selecttion
        const section = this.findSection(selection.auditSection.auditSectionId);
        if (section) {
          this.selectedSections.push(section);
        } else {
          this.additionalSections.push(selection.auditSection);
        }
      }
      this.debounceGetData();
    },
    clearAdditions() {
      this.additionalKpis = [];
      this.additionalSections = [];
    }
  }
};
</script>
