<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-primary">
      <p class="modal-card-title">Add KPI</p>
      <button type="button" class="delete" @click="close" />
    </header>
    <section class="modal-card-body">
      <div class="box">
        <AuditTypeSelect v-model="auditTypeId" />
        <AuditCategorySelect :auditTypeId="auditTypeId" v-model="auditCategoryId" />
        <AuditSectionSelect :auditCategoryId="auditCategoryId" v-model="auditSection" />
        <AuditQuestionSelect v-if="showIndividualKpis" :auditSectionId="auditSectionId" v-model="auditQuestionId" />
      </div>
    </section>

    <footer class="modal-card-foot">
      <button class="button" type="button" @click="close">Cancel</button>
      <button class="button is-primary" :loading="isLoading" :disabled="!canSubmit" @click="addKpi">Add KPI</button>
    </footer>
  </div>
</template>

<script>
import AuditTypeSelect from "@/components/kpi/AuditTypeSelect.vue";
import AuditCategorySelect from "@/components/kpi/AuditCategorySelect.vue";
import AuditSectionSelect from "@/components/kpi/AuditSectionSelect.vue";
import AuditQuestionSelect from "@/components/kpi/AuditQuestionSelect.vue";

export default {
  components: { AuditTypeSelect, AuditCategorySelect, AuditSectionSelect, AuditQuestionSelect },
  props: { showIndividualKpis: { type: Boolean, required: true } },
  data() {
    return {
      auditTypeId: null,
      auditCategoryId: null,
      auditSection: null,
      auditQuestionId: null,
      isLoading: false
    };
  },
  computed: {
    auditSectionId() {
      return this.auditSection?.auditSectionId;
    },
    canSubmit() {
      return (this.showIndividualKpis && this.auditQuestionId) || (!this.showIndividualKpis && this.auditSectionId);
    }
  },
  methods: {
    addKpi() {
      this.$emit("addKpi", {
        auditTypeId: this.auditTypeId,
        auditCategoryId: this.auditCategoryId,
        auditSection: this.auditSection,
        auditQuestionId: this.auditQuestionId
      });
      this.close();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
