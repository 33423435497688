<template>
  <b-field label="Area" horizontal>
    <b-select placeholder="Select KPI Area" :value="value" @input="selectSection">
      <option v-for="option in sections" :value="option" :key="option.auditSectionId">
        {{ option.header }}
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  props: {
    auditCategoryId: { type: Number, default: 0 },
    value: { type: Object }
  },
  data() {
    return {
      isLoading: false,
      sections: []
    };
  },
  watch: {
    auditCategoryId: {
      immediate: true,
      handler: "getSections"
    }
  },
  methods: {
    async getSections() {
      this.sections = [];
      this.selectSection(null);
      // must have an Audit category selected
      if (!this.auditCategoryId) return;
      await this.$http
        .get("audittypes/sections/" + this.auditCategoryId)
        .then(r => {
          this.sections = r.data;
          if (this.sections.length === 1) {
            this.selectSection(this.sections[0]);
          } else {
            this.selectSection(null);
          }
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error loading sections"));
    },
    selectSection(v) {
      this.$emit("input", v);
    }
  }
};
</script>
